export enum ProgrammeManagementSlColumns {
  title = 'title',
  company = 'company',
  projectCategory = 'projectCategory',
  projectProposalStage = 'projectProposalStage',
  projectStatus = 'projectStatus',
  currentStage = 'currentStage',
  creditIssued = 'creditIssued',
  creditBalance = 'creditBalance',
  purposeOfCreditDevelopment = 'purposeOfCreditDevelopment',
  creditRetired = 'creditRetired',
  creditTransferred = 'creditTransferred',
  certifierId = 'certifierId',
  serialNo = 'serialNo',
  action = 'action',
  emissionReductionExpected = 'emissionReductionExpected',
  emissionReductionAchieved = 'emissionReductionAchieved',
  emissionReductionAchievedandCreditIssued = 'emissionReductionAchievedandCreditIssued',
}
