export enum DocType {
  DESIGN_DOCUMENT = '0',
  METHODOLOGY_DOCUMENT = '1',
  MONITORING_REPORT = '2',
  VERIFICATION_REPORT = '3',
  ENVIRONMENTAL_IMPACT_ASSESSMENT = '7',
  COST_QUOTATION = '8',
  PROPOSAL = '9',
  VALIDATION_AGREEMENT = '10',
  CMA = '11',
  VALIDATION_REPORT = '12',
  PROJECT_REGISTRATION_CERTIFICATE = '13',
  SITE_VISIT_CHECKLIST = '14',
}

export enum DocumentTypeEnum {
  MONITORING_REPORT = 'monitoringReport',
  VERIFICATION_REPORT = 'verificationReport',
  CMA = 'cma',
  COST_QUOTATION = 'costQuotation',
  PROJECT_PROPOSAL = 'projectProposal',
  VALIDATION_AGREEMENT = 'validationAgreement',
  ENVIRONMENTAL_IMPACT_ASSESSMENT = 'environmentalImpactAssesment',
  VALIDATION_REPORT = 'validationReport',
  SITE_VISIT_CHECKLIST = 'siteVisitChecklist',
}
