/* eslint-disable @typescript-eslint/no-unused-expressions */
import { DownloadOutlined, EllipsisOutlined } from '@ant-design/icons';
import {
  Row,
  Checkbox,
  message,
  Tag,
  PaginationProps,
  Col,
  Input,
  Table,
  Empty,
  Popover,
  List,
  Typography,
  Form,
  Tooltip,
} from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import './programmeTransferManagement.scss';
import './creditRetirementSl.scss';
import '../../../Styles/common.table.scss';
import * as Icon from 'react-bootstrap-icons';
import { TooltipColor } from '../../../Styles/role.color.constants';
import { CircleFlag } from 'react-circle-flags';
import { creditUnit } from '../../../Definitions/Definitions/common.definitions';
import { useUserContext } from '../../../Context/UserInformationContext/userInformationContext';
import {
  addCommSepRound,
  addSpaces,
  getCompanyBgColor,
  getCreditTypeName,
  getCreditTypeTagType,
  getStageTransferEnumVal,
  getTransferStageTagType,
} from '../../../Definitions/Definitions/programme.definitions';
import { useConnection } from '../../../Context/ConnectionContext/connectionContext';
import { ProgrammeTransfer } from '../../../Definitions/Entities/programmeTransfer';
import { useSettingsContext } from '../../../Context/SettingsContext/settingsContext';
import { CompanyRole } from '../../../Definitions/Enums/company.role.enum';
import { Role } from '../../../Definitions/Enums/role.enum';
import { ProfileIcon } from '../../../Components/IconComponents/ProfileIcon/profile.icon';
import { ConfigurationSettingsType } from '../../../Definitions/Definitions/settings.definitions';
import { CreditRetirementSlActionModel } from '../../Models/creditRetirementSlActionModel';
import { CreditRetirementSl } from '../../../Definitions/Entities/creditRetirementSl';
import { CreditTransferSlStage, CreditTypeSl } from '../../../Definitions/Enums/creditTypeSl.enum';

type PopupInfo = {
  title: string;
  icon: any;
  actionBtnText: string;
  okAction: any;
  type: 'primary' | 'danger';
  remarkRequired: boolean;
};

export const CreditRetirementSlComponent = (props: any) => {
  const { translator, onNavigateToProgrammeView } = props;
  const { userInfoState } = useUserContext();
  const t = translator.t;

  const statusOptions = Object.keys(CreditTransferSlStage).map((k, index) => ({
    label: addSpaces(Object.values(CreditTransferSlStage)[index]),
    value: k,
  }));

  const [selectedStatus, setSelectedStatus] = useState<any>(statusOptions.map((e) => e.value));
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(true);

  const { post, get } = useConnection();
  const [totalProgramme, setTotalProgramme] = useState<number>();
  const [dataFilter, setDataFilter] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [search, setSearch] = useState<string>();
  const [searchText, setSearchText] = useState<string>();
  const [statusFilter, setStatusFilter] = useState<any>();
  const [sortOrder, setSortOrder] = useState<string>();
  const [sortField, setSortField] = useState<string>();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [selectedReq, setSelectedReq] = useState<CreditRetirementSl>();
  const [popupInfo, setPopupInfo] = useState<PopupInfo>();
  const { isTransferFrozen, setTransferFrozen } = useSettingsContext();
  const [ministrySectoralScope, setMinistrySectoralScope] = useState<any[]>([]);
  const [ministryLevelFilter, setMinistryLevelFilter] = useState<boolean>(false);
  const [dataQuery, setDataQuery] = useState<any>();

  const onStatusQuery = async (checkedValues: CheckboxValueType[]) => {
    if (checkedValues !== selectedStatus) {
      setSelectedStatus(checkedValues);
    }

    if (checkedValues.length === 0) {
      setTableData([]);
      setTotalProgramme(0);
      return;
    }
    setStatusFilter({
      key: 'status',
      operation: 'in',
      value: checkedValues,
    });
  };
  const [formModal] = Form.useForm();
  const { Search } = Input;

  const onCheckAllChange = (e: any) => {
    const nw = e.target.checked ? statusOptions.map((el) => el.value) : [];
    setSelectedStatus(nw);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
    onStatusQuery(nw);
  };

  const getAllTransfers = async () => {
    setLoading(true);
    const filter: any[] = [];
    if (statusFilter) {
      filter.push(statusFilter);
    }

    if (search && search !== '') {
      const interFilterOr = [
        {
          key: 'programmeTitle',
          operation: 'like',
          value: `%${search}%`,
        },
        {
          key: 'txRef',
          operation: 'like',
          value: `%${search}%`,
        },
      ];
      if (!isNaN(Number(search))) {
        interFilterOr.push({
          key: 'requestId',
          operation: '=',
          value: `${search}`,
        });
      }
      filter.push({
        value: {
          page: currentPage,
          size: pageSize,
          filterOr: interFilterOr,
        },
      });
    }

    let sort: any;
    if (sortOrder && sortField) {
      sort = {
        key:
          sortField === 'fromCompany'
            ? 'fromCompanyId'
            : sortField === 'toCompany'
            ? 'toCompanyId'
            : sortField,
        order: sortOrder,
        nullFirst: false,
      };
    } else {
      sort = {
        key: 'requestId',
        order: 'DESC',
      };
    }

    let filterBy: any;

    try {
      const response: any = await post('national/retire/query', {
        page: currentPage,
        size: pageSize,
        filterAnd: filter,
        filterOr: dataFilter,
        sort: sort,
        filterBy: filterBy,
      });

      setTableData(response.data);
      setTotalProgramme(response.response.data.total);
      setDataQuery({
        filterAnd: filter,
        filterOr: dataFilter,
        sort: sort,
        filterBy: filterBy,
      });
      setLoading(false);
    } catch (error: any) {
      console.log('Error in getting programme transfers', error);
      message.open({
        type: 'error',
        content: error.message,
        duration: 3,
        style: { textAlign: 'right', marginRight: 15, marginTop: 10 },
      });
      setLoading(false);
    }
  };

  const downloadVoluntaryCancelationCertificate = async (url: string) => {
    setLoading(true);
    try {
      if (url !== undefined && url !== '') {
        const response = await fetch(url); // Ensure the URL is fetched properly
        if (response.ok) {
          const blob = await response.blob(); // Create a blob from the response
          const downloadUrl = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = downloadUrl;
          a.download = url.split('/').pop() || 'Voluntary_Cancellation_Certificate.pdf'; // Extract filename or provide default
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(downloadUrl); // Clean up the created object URL
        } else {
          message.open({
            type: 'error',
            content: response.statusText,
            duration: 3,
            style: { textAlign: 'right', marginRight: 15, marginTop: 10 },
          });
        }
      }
      setLoading(false);
    } catch (error: any) {
      console.log('Error in exporting transfers', error);
      message.open({
        type: 'error',
        content: error.message,
        duration: 3,
        style: { textAlign: 'right', marginRight: 15, marginTop: 10 },
      });
      setLoading(false);
    }
  };

  const getUserDetails = async () => {
    setLoading(true);
    try {
      const userId = userInfoState?.id ? parseInt(userInfoState.id) : userInfoState?.id;
      const response: any = await post('national/user/query', {
        page: 1,
        size: 10,
        filterAnd: [
          {
            key: 'id',
            operation: '=',
            value: userId,
          },
        ],
      });
      if (response && response.data) {
        if (
          response?.data[0]?.companyRole === CompanyRole.MINISTRY &&
          response?.data[0]?.company &&
          response?.data[0]?.company?.sectoralScope
        ) {
          setMinistrySectoralScope(response?.data[0]?.company?.sectoralScope);
        }
      }
      setLoading(false);
    } catch (error: any) {
      console.log('Error in getting users', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    } else {
      getAllTransfers();
    }
  }, [statusFilter, dataFilter]);

  useEffect(() => {
    getAllTransfers();
  }, [currentPage, pageSize, sortField, sortOrder, search, ministryLevelFilter]);

  useEffect(() => {
    if (userInfoState?.companyRole === CompanyRole.MINISTRY) {
      getUserDetails();
    }
  }, []);

  const handleRequestOk = async (
    reqId: number,
    remarks: string,
    updatedStatus: string,
    successText?: string
  ) => {
    setLoading(true);
    try {
      const response: any = await post('national/retire/status', {
        requestId: reqId,
        status: updatedStatus,
        comment: remarks,
      });
      const successMsg = response.message;
      message.open({
        type: 'success',
        content: successText ? successText : successMsg,
        duration: 3,
        style: { textAlign: 'right', marginRight: 15, marginTop: 10 },
      });
      setLoading(false);
      getAllTransfers();
      setModalVisible(false);
    } catch (error: any) {
      console.log('Error in Cancelling transfer request', error);
      setLoading(false);
      return error.message;
    }
  };

  const showModalOnAction = (record: any, info: PopupInfo) => {
    setSelectedReq(record);
    setModalVisible(true);
    setPopupInfo(info);
  };

  const actionMenu = (record: any) => {
    let approveSuccessMessage = t('creditTransfer:transferReqApproved');
    let popupTitle = t('creditTransfer:acceptCreditTransferTitle');
    let rejectSuccessMessage = t('creditTransfer:transferReqRejected');
    let cancelSuccessMessage = t('creditTransfer:transferReqCanceled');

    if (record.creditType === CreditTypeSl.TRACK_2) {
      popupTitle = t('creditTransfer:acceptCreditRetirementTitle');
      approveSuccessMessage = t('creditTransfer:retireReqApproved');
      rejectSuccessMessage = t('creditTransfer:retireReqRejected');
      cancelSuccessMessage = t('creditTransfer:retireReqCanceled');
    }

    if (record.status === 'Pending' && userInfoState?.userRole !== Role.ViewOnly) {
      return userInfoState?.companyId === record.fromCompanyId ? (
        <List
          className="action-menu"
          size="small"
          dataSource={[
            {
              text: t('creditTransfer:cancel'),
              icon: <Icon.ExclamationOctagon />,
              click: () => {
                showModalOnAction(record, {
                  title: t('creditTransfer:cancelTitle'),
                  icon: <Icon.ExclamationOctagon />,
                  actionBtnText: t('creditTransfer:proceed'),
                  okAction: (requestId: any, comment: any) =>
                    handleRequestOk(
                      requestId,
                      comment,
                      CreditTransferSlStage.Cancelled,
                      cancelSuccessMessage
                    ),
                  type: 'danger',
                  remarkRequired: true,
                });
              },
            },
          ]}
          renderItem={(item: any) => (
            <List.Item onClick={item.click}>
              <Typography.Text className="action-icon color-error">{item.icon}</Typography.Text>
              <span>{item.text}</span>
            </List.Item>
          )}
        />
      ) : record.status === 'Pending' && userInfoState?.companyRole === CompanyRole.CLIMATE_FUND ? (
        <List
          className="action-menu"
          size="small"
          dataSource={[
            {
              text: t('creditTransfer:accept'),
              icon: <Icon.ClipboardCheck />,
              style: 'color-primary',
              click: () => {
                showModalOnAction(record, {
                  title: popupTitle,
                  icon: <Icon.ClipboardCheck />,
                  actionBtnText: t('creditTransfer:proceed'),
                  okAction: (requestId: any, comment: any) =>
                    handleRequestOk(
                      requestId,
                      comment,
                      CreditTransferSlStage.Approved,
                      approveSuccessMessage
                    ),
                  type: 'primary',
                  remarkRequired: false,
                });
              },
            },
            {
              text: t('creditTransfer:reject'),
              icon: <Icon.XOctagon />,
              style: 'color-error',
              click: () => {
                showModalOnAction(record, {
                  title: t('creditTransfer:rejectTitle'),
                  icon: <Icon.XOctagon />,
                  actionBtnText: t('creditTransfer:reject'),
                  okAction: (requestId: any, comment: any) =>
                    handleRequestOk(
                      requestId,
                      comment,
                      CreditTransferSlStage.Rejected,
                      rejectSuccessMessage
                    ),
                  type: 'danger',
                  remarkRequired: true,
                });
              },
            },
          ]}
          renderItem={(item: any) => (
            <List.Item onClick={item.click}>
              <Typography.Text className={`action-icon ${item.style}`}>{item.icon}</Typography.Text>
              <span>{item.text}</span>
            </List.Item>
          )}
        />
      ) : null;
    }
  };

  const columns = [
    {
      title: t('creditTransfer:requestID'),
      dataIndex: 'requestId',
      key: 'requestId',
      sorter: true,
    },
    {
      title: t('creditTransfer:transReference'),
      dataIndex: 'txRef',
      key: 'txRef',
      sorter: true,
    },
    {
      title: t('creditTransfer:date'),
      key: 'txTime',
      sorter: true,
      align: 'left' as const,
      render: (item: any, itemObj: any) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {DateTime.fromMillis(parseInt(itemObj.txTime)).toFormat('dd LLLL yyyy')}
          </div>
        );
      },
    },
    {
      title: t('creditTransfer:pName'),
      dataIndex: 'programmeTitle',
      key: 'programmeTitle',
      sorter: true,
      align: 'left' as const,
      render: (item: any) => {
        return <span className="clickable">{item}</span>;
      },
      onCell: (record: any, rowIndex: any) => {
        return {
          onClick: (ev: any) => {
            onNavigateToProgrammeView(record.programmeId);
          },
        };
      },
    },
    {
      title: t('creditTransfer:creditType'),
      dataIndex: 'creditType',
      key: 'creditType',
      sorter: true,
      align: 'center' as const,
      render: (item: any, Obj: any) => {
        return (
          <Tooltip title={Obj.serialNo} color={TooltipColor} key={TooltipColor}>
            <Tag color={getCreditTypeTagType(Obj.creditType)}>
              {addSpaces(getCreditTypeName(Obj.creditType))}
            </Tag>
          </Tooltip>
        );
      },
    },
    {
      title: t('creditTransfer:cSender'),
      key: 'fromCompany',
      sorter: true,
      align: 'left' as const,
      render: (item: any, itemObj: any) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {itemObj.fromCompany &&
              itemObj.fromCompany.map((v: any, i: any) => {
                return (
                  <Tooltip title={v.name} color={TooltipColor} key={TooltipColor}>
                    <div>
                      <ProfileIcon
                        icon={v.logo}
                        bg={getCompanyBgColor(v.companyRole)}
                        name={v.name}
                      />
                    </div>
                  </Tooltip>
                );
              })}
          </div>
        );
      },
    },
    {
      title: t('creditTransfer:cReceiver'),
      dataIndex: 'toCompany',
      key: 'toCompany',
      sorter: true,
      align: 'left' as const,
      render: (item: any, itemObj: any) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {itemObj.toCompany &&
              itemObj.toCompany.map((v: any, i: any) => {
                return !itemObj.isRetirement ? (
                  <Tooltip title={v.name} color={TooltipColor} key={TooltipColor}>
                    <div>
                      <ProfileIcon
                        icon={v.logo}
                        bg={getCompanyBgColor(v.companyRole)}
                        name={v.name}
                      />
                    </div>
                  </Tooltip>
                ) : itemObj.retirementType === '0' ? (
                  <Tooltip
                    title={
                      t('creditTransfer:iaccount') +
                      `${
                        itemObj.toCompanyMeta && itemObj.toCompanyMeta.countryName
                          ? ' - ' + itemObj.toCompanyMeta.countryName
                          : ''
                      }`
                    }
                    color={TooltipColor}
                    key={TooltipColor}
                  >
                    {itemObj.toCompanyMeta && itemObj.toCompanyMeta.country && (
                      <CircleFlag
                        className="profile-icon flag-ret-icon"
                        countryCode={itemObj.toCompanyMeta.country.toLowerCase()}
                      />
                    )}
                  </Tooltip>
                ) : (
                  <Tooltip
                    title={t('creditTransfer:laccount')}
                    color={TooltipColor}
                    key={TooltipColor}
                  >
                    <div className="ret-icon profile-icon">
                      <Icon.Save />
                    </div>
                  </Tooltip>
                );
              })}
          </div>
        );
      },
    },
    {
      title: t('creditTransfer:cRequested') + ` (${creditUnit})`,
      dataIndex: 'creditAmount',
      key: 'creditAmount',
      sorter: true,
      align: 'right' as const,
      render: (item: any) => {
        return <span className="clickable">{addCommSepRound(item)}</span>;
      },
    },
    {
      title: t('programme:status'),
      key: 'status',
      sorter: true,
      align: 'center' as const,
      render: (item: any, Obj: any) => {
        return (
          <Tooltip title={Obj.programmeSerialNo} color={TooltipColor} key={Obj.programmeSerialNo}>
            <Tag color={getTransferStageTagType(Obj.status, Obj)}>
              {addSpaces(getStageTransferEnumVal(Obj.status, Obj))}
            </Tag>
          </Tooltip>
        );
      },
    },
    {
      align: 'right' as const,
      render: (_: any, record: any) => {
        return record && record.voluntaryCancelationCertificateUrl ? (
          <Tooltip
            title={t('creditTransfer:downloadVoluntaryCertificate')}
            color={TooltipColor}
            key={record.txRef}
          >
            <div className="download-data-btn">
              <a
                onClick={() =>
                  downloadVoluntaryCancelationCertificate(record.voluntaryCancelationCertificateUrl)
                }
              >
                <DownloadOutlined
                  style={{
                    color: 'rgba(58, 53, 65, 0.3)',
                    fontSize: '20px',
                  }}
                />
              </a>
            </div>
          </Tooltip>
        ) : (
          <span></span>
        );
      },
    },
    {
      align: 'right' as const,
      render: (_: any, record: any) => {
        const menu = actionMenu(record);
        return menu && !isTransferFrozen ? (
          <Popover placement="bottomRight" content={menu} trigger="click">
            <EllipsisOutlined
              rotate={90}
              style={{ fontWeight: 600, fontSize: '1rem', cursor: 'pointer' }}
            />
          </Popover>
        ) : (
          <span></span>
        );
      },
    },
  ];

  const onSearch = async () => {
    setSearch(searchText);
  };

  const onChange: PaginationProps['onChange'] = (page, size) => {
    setCurrentPage(page);
    setPageSize(size);
  };

  const handleTableChange = (pag: any, sorter: any) => {
    setSortOrder(
      sorter.order === 'ascend' ? 'ASC' : sorter.order === 'descend' ? 'DESC' : undefined
    );
    setSortField(sorter.columnKey);
    // setCurrentPage(1);
  };

  // const onFreezeTransfer = async () => {
  //   const response = await post('national/Settings/update', {
  //     id: ConfigurationSettingsType.isTransferFrozen,
  //     settingValue: `${!isTransferFrozen}`,
  //   });
  //   if (response) {
  //     setTransferFrozen(!isTransferFrozen);
  //   }
  // };

  return (
    <div className="credit-transfer-management content-container">
      <div className="title-bar title-bar-transfer">
        <Row justify="space-between" align="middle">
          <Col span={20}>
            <div className="body-title">{t('creditTransfer:viewCreditsRetirements')}</div>
          </Col>
        </Row>
      </div>
      <div className="content-card">
        <Row>
          <Col lg={{ span: 16 }} md={{ span: 16 }}>
            <div className="action-bar">
              <Checkbox
                className="all-check"
                indeterminate={indeterminate}
                onChange={onCheckAllChange}
                checked={checkAll}
                defaultChecked={true}
              >
                {t('creditTransfer:all')}
              </Checkbox>
              <Checkbox.Group
                options={statusOptions}
                defaultValue={statusOptions.map((e) => e.value)}
                value={selectedStatus}
                onChange={onStatusQuery}
              />
            </div>
          </Col>
          <Col lg={{ span: 8 }} md={{ span: 8 }}>
            <div className="filter-section">
              <div className="search-bar">
                <Search
                  onPressEnter={onSearch}
                  placeholder={'Search'}
                  allowClear
                  onChange={(e) =>
                    e.target.value === ''
                      ? setSearch(e.target.value)
                      : setSearchText(e.target.value)
                  }
                  onSearch={setSearch}
                  style={{ width: 265 }}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className="programmeManagement-table-container">
              <Table
                dataSource={tableData}
                columns={columns}
                className="common-table-class"
                loading={loading}
                pagination={{
                  current: currentPage,
                  pageSize: pageSize,
                  total: totalProgramme,
                  showQuickJumper: true,
                  showSizeChanger: true,
                  onChange: onChange,
                }}
                onChange={(val: any, filter: any, sorter: any) => handleTableChange(val, sorter)}
                locale={{
                  emptyText: (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description={
                        tableData.length === 0 ? t('creditTransfer:noRetirements') : null
                      }
                    />
                  ),
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
      {popupInfo && selectedReq && (
        <CreditRetirementSlActionModel
          transfer={selectedReq!}
          onCancel={() => {
            setModalVisible(false);
            setSelectedReq(undefined);
          }}
          actionBtnText={popupInfo!.actionBtnText}
          onFinish={popupInfo?.okAction}
          subText={''}
          openModal={modalVisible}
          icon={popupInfo!.icon}
          title={popupInfo!.title}
          type={popupInfo!.type}
          remarkRequired={popupInfo.remarkRequired}
          translator={translator}
        />
      )}
    </div>
  );
};
